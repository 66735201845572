<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <recipient-card
          @update="checkUpdate"
          :recipient-data.sync="recipientData"
          :should-reset="tempIsAnnouncementCreated"
        ></recipient-card>
      </v-col>
      <v-col cols="5" class="px-6">
        <message-form-card
          :recipient="recipientData"
          @submitted="updateTable"
        ></message-form-card>
      </v-col>
      <v-col cols="4">
        <announcement-card v-if="refreshTable"></announcement-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const RecipientCard = () => import("../cards/RecipientCard.vue");
const MessageFormCard = () => import("../cards/MessageFormCard.vue");
const AnnouncementCard = () => import("../cards/AnnouncementCard.vue");

export default {
  components: {
    RecipientCard,
    MessageFormCard,
    AnnouncementCard
  },
  data: () => ({
    recipientData: {
      type: "all",
      data: []
    },
    refreshTable: true,
    tempIsAnnouncementCreated: false
  }),
  methods: {
    checkUpdate(data, type) {
      this.recipientData = Object.assign(
        {},
        {
          type: type,
          data: data
        }
      );
    },
    updateTable() {
      this.tempIsAnnouncementCreated = true;
      this.refreshTable = false;
      setTimeout(() => {
        this.refreshTable = true;
        this.tempIsAnnouncementCreated = false;
      }, 300);
    }
  }
};
</script>
